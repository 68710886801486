// @flow

import React from "react";

const Ctx = React.createContext();

// Styled Components
// ==============================

const ToastContainer = props => (    
    <div style={{ position: "fixed", right: 0, top: 0 }} {...props} />
);
const Toast = ({ children, onDismiss }) => (
    <div
        style={{
            background: "LemonChiffon",
            cursor: "pointer",
            fontSize: 14,
            margin: 10,
            padding: 10
        }}
        onClick={onDismiss}
    >
        {children}
    </div>
);

// Provider
// ==============================

let toastCount = 0;

export function ToastProvider({ children }) {
    const [toasts, setToasts] = React.useState([]);

    const add = content => {
        const id = toastCount++;
        const toast = { content, id };
        setToasts([...toasts, toast]);
    };
    const remove = id => {
        const newToasts = toasts.filter(t => t.id !== id);
        setToasts(newToasts);
    };
    // avoid creating a new fn on every render
    const onDismiss = id => () => remove(id);

    return (
        <Ctx.Provider value={{ add, remove }}>
            {children}
            <ToastContainer>
                {toasts.map(({ content, id, ...rest }) => (
                    <Toast key={id} Toast={Toast} onDismiss={onDismiss(id)} {...rest}>
                        {id + 1} &mdash; {content}
                    </Toast>
                ))}
            </ToastContainer>
        </Ctx.Provider>
    );
}

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx);
