import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTable from "react-table-6";
import { Container, Collapse, Tooltip } from 'reactstrap'
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faFilter } from '@fortawesome/free-solid-svg-icons'
import backendAPI from '../../../../services/backend'
import UsersSearchCriteria from '../UserSearch/UsersSearchCriteria'
import AlertMessage from '../../../alertMessage/AlertMessage'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'
import constants from '../../../../constants'

import './UsersManagement.css'

const defaultPageSize = 15
const defaultPages = 15

const UsersManagement = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const history = useHistory()
    const [users, setUsers] = useState(undefined)
    const [pageSizeProp, setPageSize] = useState(defaultPageSize)
    const [pagesProp, setPages] = useState(defaultPages)
    const [user, setUser] = useState()
    const [region, setRegion] = useState()
    const [isUsersSearchCriteriaOpen, setIsUsersSearchCriteriaOpen] = useState(true)
    const [searchCriteria, setSearchCriteria] = useState()
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [role, setRole] = useState()
    // const [clientsList, setClientsList] = useState([])
    // const [rolesList, setRolesList] = useState([])
    const [waitingHeader, setWaitingHeader] = useState()

    //const [searchUsersTooltipOpen, setSearchUsersTooltipOpen] = useState(false)
    const [createUsersTooltipOpen, setCreateUsersTooltipOpen] = useState(false)

    const fetchAllUserData = async () => {
        try {
            
            setIsWaitingModalOpen(true)
            setWaitingHeader("Loading users")
            const allUserResponse = await backendAPI.getUserListAll(sessionStorage.getItem(constants.GC_TOKEN));
            console.log(`All Users list is ${JSON.stringify(allUserResponse)}`)
            setUsers(allUserResponse.users);
            let currentuser = JSON.parse(sessionStorage.getItem(constants.SESSION_KEY_GETUSER_ME_RESPONSE));
                //console.log(`Logged in User Role data type is ${typeof currentuser.user.roles}`);
                //console.log(`Logged in User Role is ${JSON.stringify(currentuser.user.roles)}`);
                //console.log(`Logged in User Roles is ${JSON.stringify(currentuser.user.roles)}`);
                //console.log(`Logged in User Keys are ${Object.keys(currentuser.user)}`);
            for (let myuser of allUserResponse.users){
                try{
                //console.log(`User is ${JSON.stringify(myuser)}`)
                //console.log(`User is ${JSON.stringify(user.roles.filter((x => x.value == myuser.role)))}`)
                myuser.role = currentuser.user.roles.filter((x => x.value == myuser.role.value))[0]
                console.log(`User is ${JSON.stringify(myuser)}`)
                }catch(exception){
                    	console.log(exception);
                }
            }
            sessionStorage.setItem(constants.SESSION_KEY_GETALLUSERS_RESPONSE, JSON.stringify(allUserResponse))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

    }

    // const removeAll = (arrayToModify) => {
    //     const deleteIndex = arrayToModify.findIndex(obj => obj.value === "*")
    //     if ((deleteIndex > -1) && (arrayToModify.length > 1))
    //         arrayToModify.splice(deleteIndex, 1)
    //     return arrayToModify
    // }


    const toggleUsersSearchCriteria = () => {
        setIsUsersSearchCriteriaOpen(!isUsersSearchCriteriaOpen)
    }

    const search = async (searchCriteria) => {
        console.log('getUsers.searchCriteria:', searchCriteria)
        setSearchCriteria(searchCriteria)
        fetchData(searchCriteria)
        toggleUsersSearchCriteria(false)
    }

    const fetchData = async (searchCriteria) => {
        try {
            setIsWaitingModalOpen(true)
            setWaitingHeader("Fetching Users")
            const getUsersResponse = await backendAPI.getUsersListSearch(sessionStorage.getItem(constants.GC_TOKEN), searchCriteria)
            
            setUsers(getUsersResponse.users)
            setPages(getUsersResponse.pageCount)
            sessionStorage.setItem(constants.SESSION_KEY_GETUSERS_RESPONSE, JSON.stringify(getUsersResponse))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }


    useEffect(() => {
        console.log('Users Management :: Begin useEffects');
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        const fetchUserMe = async () => {
            try {
                console.log('Users Management   :: Begin fetchUserMe');
                const userMeResponse = await backendAPI.getUsersMe(sessionStorage.getItem(constants.GC_TOKEN))
                setUser(userMeResponse.user)
                //console.log(`Users Management region:: ${JSON.stringify(userMeResponse.user.regions)}`);
                setRegion(userMeResponse.user.regions)
                sessionStorage.setItem(constants.SESSION_KEY_GETUSER_ME_RESPONSE, JSON.stringify(userMeResponse))
                console.log(`Users Management :: End fetchUserMe Roles ${JSON.stringify(userMeResponse.user.roles)}`)
                console.log('Users Management :: End fetchUserMe');
            } catch (error) {
                console.log('Users Management :: Error in fetchUserMe');
                setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
                console.log(error)
            } 
    
        }

//        fetchUserMe().then;
        fetchUserMe().then(
            () => {
                fetchAllUserData();
            }
          );
        
        //console.log(`Users are : ${JSON.stringify(sessionStorage.getItem(constants.SESSION_KEY_GETUSER_ME_RESPONSE))}`);


    }, [])



    return (
        <div id="component-wrap">
            <Header role={role} />
            <WaitingModal
                        isOpen={isWaitingModalOpen}
                        header={waitingHeader}
                    />
                    <AlertModal
                        isOpen={!!error}
                        header='Error'
                        toggle={() => { setError(undefined) }}
                        body={error}
                    />
                    
                    <Tooltip placement="bottom" isOpen={createUsersTooltipOpen}
                        target="createUsers"
                        toggle={() => { setCreateUsersTooltipOpen(!createUsersTooltipOpen) }}>
                        Create User
                    </Tooltip>
                 <div id="component-toolbar">
                    
                    <button id = "createUsers" onClick={() =>
                        history.push('/management/users/create')}><FontAwesomeIcon className="fa-2x" icon={faUserPlus} /></button>
                        </div>
                    <AlertMessage
                        message={searchAlertMessage}
                        visible={searchAlertVisible}
                        setVisible={x => { setSearchAlertVisible(x) }}
                    />
                    
                    <ReactTable
                        showPageSizeOptions={true}
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                        defaultPageSize={pageSizeProp}
                        minRows={0}
                        filterable
                        // loading = {loadState}
                        //  LoadingComponent = {Spinner} 
                        // manual 
                        showPagination={true}
                        className="-highlight -striped table-container"
                        data={users}
                        columns={[
                            {
                                Header: "Email Address",
                                accessor: "email",
                                headerClassName: 'headerColumn',
                                Cell: row => {
                                    return (
                                        <div className="div-center">
                                            {row.original.email}
                                        </div>
                                    )
                                }

                            },
                            {
                                Header: "Role",
                                id: "roles",
                                accessor: role,
                                headerClassName: 'headerColumn',
                                Cell: row => {
                                    return (
                                        <div className="div-center">
                                            {row.original.role==undefined?'':row.original.role.label}
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: "Disabled",
                                id: "disabled",
                                accessor: d => { return d.disabled ? 'True' : 'False' },
                                headerClassName: 'headerColumn',
                                Cell: row => {
                                    return (
                                        <div className="div-center">
                                            {row.original.disabled ? 'True' : 'False'}
                                        </div>
                                    )
                                }
 
                            }
                        ]}
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    try {
                                        if (!rowInfo) return
                                        const userid = rowInfo.original.id;
                                        history.push({ pathname: "/management/users/update/" + userid })
                                    } catch (error) {
                                        setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
                                        console.log(error)
                                    } 
                                },
                                style: {
                                    cursor: 'pointer'
                                }
                            }
                        }}
                        onPageSizeChange={(pageSize) => { setPageSize(pageSize) }}
                        onPageChange={(pageIndex) => {
                            setPages(pageIndex)
                            // console.log(pageIndex)
                        }}
                        getTrProps={() => {
                            return {
                                style: {
                                    flex: '0 0 auto'
                                }
                            }
                        }}
                        getTrGroupProps={() => {
                            return {
                                style: {
                                    flex: '0 0 auto'
                                }
                            }
                        }}
                        defaultFilterMethod={(filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return (row[id] !== undefined ?
                                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                                : true
                            );
                        
                        }}


                    />
               
            <Footer version="0.1" />
        </div>


    )
}

export default UsersManagement


/***
 * <Tooltip placement="bottom" isOpen={searchUsersTooltipOpen}
                        target="searchUsers"
                        toggle={() => { setSearchUsersTooltipOpen(!searchUsersTooltipOpen) }}>
                        Search Users
                    </Tooltip>
 * <button id = "searchUsers" onClick={toggleUsersSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
 * <Collapse isOpen={isUsersSearchCriteriaOpen}>
                    {users && 
                        <UsersSearchCriteria
                            search={search}
                            cancel={() => { toggleUsersSearchCriteria(false) }}
                            roles={user && user.roles ? user.roles.filter((x => x.value !== '*')) : []}
                            regions={region && region ? region.filter((x => x.value !== '*')) : []}
                        />
                    }
                    </Collapse>
 */